import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import LogIn from './pages/LogIn';
import ErrorPage from './pages/ErrorPage';
import AddFiles from './pages/AddFiles';
import SearchBatch from './pages/SearchBatch';

const router = createBrowserRouter([
  {
    path: "/",
    element: <LogIn />,
    errorElement: <ErrorPage />
  },
  {
    path: "addfiles",
    element: <AddFiles />
  },
  {
    path: "search",
    element: <SearchBatch />
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

reportWebVitals();