import '../App.css';
import Spinner from 'react-bootstrap/Spinner';

function Button(props) {

  const onClick = () => {
    if (!props.isLoading && props.isEnabled) {
      props.action()
    }
  }

  return (
    <div className={props.isLoading || !props.isEnabled ? 'PrimaryButtonDisabled' : 'PrimaryButton'} onClick={() => { onClick(); }}>
      {
        props.isLoading ?
          <Spinner animation="border" role="status" size="sm" variant='primary' />
          :
          <span style={{ fontSize: 14, cursor: 'default' }}>{props.title}</span>
      }
    </div>
  );
}

export default Button;