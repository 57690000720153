import React, { useEffect, useState } from 'react';
import '../App.css';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Button from '../components/Button';
import closeIcon from '../resources/close.png';
import uploadIcon from '../resources/upload-icon.png';
import Modal from 'react-bootstrap/Modal';
import Middleware from '../core/Middleware';
import Network from '../core/Network';
import RequestMapping from '../core/RequestMapping';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';

function AddFiles() {
  const MAX_SIZE_FILE = 3000000
  const [fileList, setFileList] = useState([]);
  const [showNewProductModal, setShowNewProductModal] = useState(false);
  const [newProductId, setNewProductId] = useState('');
  const [newProductDescription, setNewProductDescription] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingNewProduct, setIsLoadingNewProduct] = useState(false);
  const [failedFileList, setFailedFileList] = useState([]);
  const [showFailedListModal, setShowFailedListModal] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [currentFileUploading, setCurrentFileUploading] = useState(null);
  const [buttonSendToServerIsEnabled, setButtonSendToServerIsEnabled] = useState(false);
  const [buttonSaveProductIsEnabled, setbuttonSaveProductIsEnabled] = useState(false);
  const handleNewProductClose = () => setShowNewProductModal(false);

  useEffect(() => {
    setButtonSendToServerIsEnabled(fileList.length > 0);
  }, [fileList]);

  useEffect(() => {
    setbuttonSaveProductIsEnabled(newProductDescription.length > 3);
  }, [newProductDescription]);

  function handleSelectFile(event) {
    var fileList = event.target.files;
    var newFileList = [];

    for (var i = 0; i < fileList.length; i++) {
      if (fileList[i] && fileList[i].type === 'application/pdf') {
        newFileList.push(fileList[i]);
      }
    }
    setFileList(newFileList);
  }

  function isNumeric(value) {
    return /^-?\d+$/.test(value);
  }

  function uploadNextItem() {
    if (fileList.length > 0) {
      var file = fileList[0]
      setCurrentFileUploading(file);
      fileList.shift();

      if (file.size > MAX_SIZE_FILE) {
        registerErrorToFile('Arquivo maior que o permitido de 3Mbs', true);
      } else {
        validateFile(file);
      }
    } else {
      setIsLoading(false);
      if (failedFileList && failedFileList.length > 0) {
        setFileList([]);
        setShowFailedListModal(true);
      } else {
        setShowToast(true);
      }
    }
  }

  function registerErrorToFile(reason, needCleanCurrentFile) {
    if (currentFileUploading) {
      failedFileList.push({ reason: reason, file: currentFileUploading });
      setFailedFileList(failedFileList);

      if (needCleanCurrentFile) {
        setCurrentFileUploading(null);
      }
    }
    uploadNextItem();
  }

  function handleFailedListClose() {
    setFailedFileList([]);
    setShowFailedListModal(false);
  };

  function handleFileSelect(event) {
    handleSelectFile(event);
  };

  function handleDrop(event) {
    handleSelectFile(event);
  };

  function handleDragOver(event) {
    event.preventDefault();
    event.stopPropagation();
    event.dataTransfer.dropEffect = 'copy';
  };

  function sendToServerDidTap() {
    if (fileList.length > 0) {
      setIsLoading(true);
      setCurrentFileUploading(null);
      setFailedFileList(null);
      uploadNextItem();
    } else {
      console.log('Selecione ao menos um arquivo')
    }
  }

  function validateFile(file) {
    var productId = file.name.split('_')[0]

    if (isNumeric(productId)) {
      Network.get(RequestMapping.GET_PRODUCTS + productId + "/").then(function () {
        sendFileToServer(file);
      }).catch(function (error) {
        if (error.response.status === 404) {
          setCurrentFileUploading(file);
          setNewProductId(productId);
          setShowNewProductModal(true);
        } else {
          registerErrorToFile('Não foi possível consultar o produto', false);
        }
      });
    } else {
      registerErrorToFile('Nome do arquivo é inválido', true);
    }
  }

  function registerNewProduct() {
    var bodyFormData = new FormData();
    bodyFormData.append('codigo_produto', newProductId);
    bodyFormData.append('descricao', newProductDescription);

    setIsLoadingNewProduct(true);
    Network.post(RequestMapping.REGISTER_PRODUCT, bodyFormData).then(() => {
      setNewProductDescription('');
      setIsLoadingNewProduct(false);
      handleNewProductClose();
      sendFileToServer(currentFileUploading);
    }).catch((error) => {
      setNewProductDescription('');
      setIsLoadingNewProduct(false);
      handleNewProductClose();

      if (error.response.status === 400) {
        registerErrorToFile('Produto com esse código já existe', false);
      } else {
        registerErrorToFile('Não foi possível cadastrar novo produto', false);
      }
    })
  }

  function sendFileToServer(file) {
    if (file) {
      var bodyFormData = new FormData();
      bodyFormData.append('arquivo', file);

      Network.post(RequestMapping.UPLOAD, bodyFormData).then(() => {
        uploadNextItem();
      }).catch(() => {
        registerErrorToFile('Não foi possível fazer o upload desse arquivo, tente novamente', true);
      })
    } else {
      registerErrorToFile('Não foi possível fazer o upload desse arquivo, pois ficou vazio', true);
    }
  }

  function removeFileFromList(file) {
    var newList = fileList.filter(function (ele) { return ele != file; });
    setFileList(newList);
  }

  return (
    <div className="PageContainer">
      <Header title="Envio de Certificado de Análise" />
      <Middleware />
      <div className="PageContent CenterHV">
        <div className='CardContent'>
          <h5 style={{ fontWeight: 'bold', textAlign: 'center' }}>Selecione os arquivos</h5>
          <div
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            onClick={() => document.getElementById('fileInput').click()}
            className="DragAndDrop"
            style={{}}>
            <input
              type="file"
              id="fileInput"
              accept="application/pdf"
              onChange={handleFileSelect}
              style={{ display: 'none' }}
              multiple
            />
            <div>
              <img src={uploadIcon} alt="ícone de upload" style={{ height: 64 }} />
              <div style={{ fontWeight: 'bold', marginTop: 16 }}>Arraste e solte ou selecione nos arquivos</div>
              <div style={{ paddingTop: 8, fontSize: 12 }}>Formato suportado: PDF.</div>
            </div>
          </div>
          <div style={{ marginTop: 16, marginBottom: 16 }}>
            {
              fileList.length > 0 ?
                fileList.map((file, index) => {
                  return (
                    <div key={index} style={{ padding: 8, borderRadius: 4, borderStyle: 'solid', borderColor: '#e8e8e8', borderWidth: 0.5, marginBottom: 8 }}>
                      <span style={{ fontSize: 12, color: '#2b2b2b' }}>{file.name}</span>
                      <img src={closeIcon} alt="ícone de upload" style={{ height: 16, float: 'right', marginTop: 5 }} onClick={() => { removeFileFromList(file) }} />
                    </div>
                  );
                })
                :
                <div />
            }
          </div>
          <Button type="submit" title="ENVIAR PARA O SERVIDOR" action={() => sendToServerDidTap()} isLoading={isLoading} isEnabled={buttonSendToServerIsEnabled}/>
        </div>
      </div>
      <Modal show={showNewProductModal} onHide={handleNewProductClose} size="lg" centered backdrop="static" keyboard={ false }>
        <Modal.Body>
          <h5 style={{ textAlign: 'center', fontWeight: '700' }}>Cadastrar novos produtos</h5>
          <div style={{ display: 'flex', marginTop: 32, marginBottom: 48 }}>
            <div>
              <div style={{ color: '#2b2b2b', fontWeight: '650' }}>
                Código do produto
              </div>
              <input style={{ marginTop: 4 }} inputMode="numeric" value={newProductId} onChange={(event) => setNewProductId(event.target.value)} />
            </div>
            <div style={{ flex: 1, marginLeft: 16 }}>
              <div style={{ color: '#2b2b2b', fontWeight: '650' }}>
                Descrição do produto
              </div>
              <input style={{ width: '-webkit-fill-available', marginTop: 4 }} value={newProductDescription} onChange={(event) => setNewProductDescription(event.target.value)} />
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button type="submit" title="CADASTRAR PRODUTOS" action={() => registerNewProduct()} isLoading={isLoadingNewProduct} isEnabled={buttonSaveProductIsEnabled} />
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={showFailedListModal} onHide={() => { handleFailedListClose(); }} size="lg" centered>
        <Modal.Body>
          <h5 style={{ textAlign: 'center', fontWeight: '700' }}>Os seguintes itens não foram enviados</h5>
          <div style={{ display: 'flex', marginTop: 32, marginBottom: 48 }}>
            {
              failedFileList == null ? <div /> :
                failedFileList.map((file, index) => {
                  return (
                    <div key={index} style={{ padding: 8, borderRadius: 4, borderStyle: 'solid', borderColor: '#e8e8e8', borderWidth: 0.5, marginBottom: 8, width: '-webkit-fill-available' }}>
                      <span style={{ fontSize: 14, color: '#2b2b2b' }}>{file.file.name}</span>
                      <div>
                        <span style={{ fontSize: 10, color: '#2b2b2b' }}><b>Motivo:</b> {file.reason}</span>
                      </div>
                    </div>
                  );
                })
            }
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button type="submit" title="Ok, entendi" action={() => handleFailedListClose()} />
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer className="p-3" position="top-center" >
        <Toast bg='primary' show={showToast} onClose={() => setShowToast(false)} delay={3000} autohide>
          <Toast.Header closeButton={true} >
            <strong className="me-auto">Sucesso!</strong>
          </Toast.Header>
          <Toast.Body className="set-color-white">Todos os arquivos foram enviados</Toast.Body>
        </Toast>
      </ToastContainer>
      <Footer />
    </div>
  );
}

export default AddFiles;