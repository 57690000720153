import Constants from "./Constants";
import axios from "axios";
import RequestMapping from "./RequestMapping";
import LocalStorageKey from "./LocalStorageKey";
import moment from "moment";

class Network {

  static needsUpdateToken() {
    var storedExpiresIn = localStorage.getItem(LocalStorageKey.EXPIRES_IN);
    var storedTokenTime = localStorage.getItem(LocalStorageKey.TOKEN_TIME);

    if (storedExpiresIn && storedTokenTime) {
      var currentTime = moment();
      var expiresInMinutes = storedExpiresIn / 60 / 60;
      var expiresTime = moment(storedTokenTime).add(expiresInMinutes, 'm');
      return currentTime > expiresTime
    } else {
      return true
    }
  }

  static getAuthorizationToken() {
    return localStorage.getItem(LocalStorageKey.TOKEN);
  }

  static logIn(bodyFormData) {
    return new Promise((resolve, reject) => {
      axios({
        method: "post",
        url: Constants.BASE_URL + RequestMapping.LOGIN,
        data: bodyFormData,
        headers: {
          "Content-Type": "multipart/form-data"
        },
      })
        .then(function (response) {
          resolve(response);
        })
        .catch(function (response) {
          reject(response);
        });
    });
  }

  static refreshToken() {
    var bodyFormData = new FormData();
    bodyFormData.append('client_id', Constants.CLIENT_ID);
    bodyFormData.append('client_secret', Constants.CLIENT_SECRET);
    bodyFormData.append('grant_type', Constants.GRANT_TYPE_REFRESH_TOKEN);
    bodyFormData.append('refresh_token', localStorage.getItem(LocalStorageKey.REFRESH_TOKEN));

    return new Promise((resolve, reject) => {
      axios({
        method: "post",
        url: Constants.BASE_URL + RequestMapping.LOGIN,
        data: bodyFormData,
        headers: {
          "Content-Type": "multipart/form-data"
        },
      })
        .then(function (response) {
          localStorage.setItem(LocalStorageKey.TOKEN, response.data.access_token);
          localStorage.setItem(LocalStorageKey.REFRESH_TOKEN, response.data.refresh_token);
          localStorage.setItem(LocalStorageKey.EXPIRES_IN, response.data.expires_in);
          localStorage.setItem(LocalStorageKey.TOKEN_TIME, moment());
          resolve(response);
        })
        .catch(function (response) {
          reject(response);
        });
    })
  }

  static post(path, bodyFormData) {
    return new Promise((resolve, reject) => {
      if (this.needsUpdateToken()) {
        this.refreshToken().then(() => {
          axios({
            method: "post",
            url: Constants.BASE_URL + path + '/',
            data: bodyFormData,
            headers: {
              "Authorization": "Bearer " + this.getAuthorizationToken(),
              "Content-Type": "multipart/form-data"
            },
          })
            .then(function (response) {
              resolve(response);
            })
            .catch(function (response) {
              reject(response);
            });
        }).catch(() => {
          reject();
        });
      } else {
        axios({
          method: "post",
          url: Constants.BASE_URL + path + '/',
          data: bodyFormData,
          headers: {
            "Authorization": "Bearer " + this.getAuthorizationToken(),
            "Content-Type": "multipart/form-data"
          },
        })
          .then(function (response) {
            resolve(response);
          })
          .catch(function (response) {
            reject(response);
          });
      }
    });
  }

  static get(path) {
    return new Promise((resolve, reject) => {
      if (this.needsUpdateToken()) {
        this.refreshToken().then(() => {
          axios({
            method: "get",
            url: Constants.BASE_URL + path,
            headers: {
              "Authorization": "Bearer " + this.getAuthorizationToken()
            },
          })
            .then(function (response) {
              resolve(response);
            })
            .catch(function (error) {
              reject(error);
            });
        }).catch(() => {
          reject();
        });
      } else {
        axios({
          method: "get",
          url: Constants.BASE_URL + path,
          headers: {
            "Authorization": "Bearer " + this.getAuthorizationToken()
          },
        })
          .then(function (response) {
            resolve(response);
          })
          .catch(function (error) {
            reject(error);
          });
      }
    });
  }

  static getWithoutToken(path) {
    return new Promise((resolve, reject) => {
      axios({
        method: "get",
        url: Constants.BASE_URL + path,
        headers: {
          "Authorization": "Bearer " + this.getAuthorizationToken()
        },
      })
        .then(function (response) {
          resolve(response);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  }
}

export default Network;