import { useEffect, useState } from 'react';
import '../App.css';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Button from '../components/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { Navigate } from "react-router-dom";
import LocalStorageKey from '../core/LocalStorageKey';
import Network from '../core/Network';
import Constants from '../core/Constants';
import moment from 'moment';

function LogIn() {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessageVisible, setErrorMessageVisible] = useState(false);
    const [errorModalVisible, setErrorModalVisible] = useState(false);
    const [isLogged, setIsLogged] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [buttonIsEnabled, setButtonIsEnabled] = useState(false);
    const handleClose = () => setErrorModalVisible(false);

    useEffect(() => {
        setButtonIsEnabled(username != '' && password != '');
    }, [username, password])

    const logInButtonTapped = () => {

        if (username && username != '' && password && password != '') {
            var bodyFormData = new FormData();
            bodyFormData.append('client_id', Constants.CLIENT_ID);
            bodyFormData.append('client_secret', Constants.CLIENT_SECRET);
            bodyFormData.append('grant_type', Constants.GRANT_TYPE);
            bodyFormData.append('username', username);
            bodyFormData.append('password', password);
            setErrorMessageVisible(false);
            setIsLoading(true);

            Network.logIn(bodyFormData).then((response) => {
                localStorage.setItem(LocalStorageKey.TOKEN, response.data.access_token);
                localStorage.setItem(LocalStorageKey.REFRESH_TOKEN, response.data.refresh_token);
                localStorage.setItem(LocalStorageKey.EXPIRES_IN, response.data.expires_in);
                localStorage.setItem(LocalStorageKey.TOKEN_TIME, moment());

                setIsLoading(false);
                setIsLogged(true);
            }).catch((error) => {
                setIsLoading(false);
                setErrorModalVisible(true);
            });
        } else {
            setErrorMessageVisible(true);
        }
    }

    function keyboardPressKey(event) {
        if ((event.key === "Enter" || event.keyCode === 13) && buttonIsEnabled) {
            logInButtonTapped();
        }
    }

    return (
        <div className="PageContainer">
            <Header title="Envio de Certificado de Análise" />
            <div className="PageContent CenterHV">
                <div className='CardContent FormLogin'>
                    <h5 style={{ fontWeight: 'bold', textAlign: 'center' }}>Efetuar Login</h5>
                    <div style={{ fontSize: 12, textAlign: 'center' }}>Preencha os campos abaixo</div>
                    <Form style={{ paddingTop: 24 }}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label className='LabelForm'>Usuário</Form.Label>
                            <Form.Control type="email" placeholder="" value={username} onChange={(event) => setUsername(event.target.value)} onKeyDown={(event) => keyboardPressKey(event)} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicPassword" style={{ paddingBottom: 16 }}>
                            <Form.Label className='LabelForm'>Senha </Form.Label>
                            <Form.Control type="password" placeholder="" value={password} onChange={(event) => setPassword(event.target.value)} onKeyDown={(event) => keyboardPressKey(event)} />
                        </Form.Group>
                        {
                            errorMessageVisible ?
                                <div style={{ marginBottom: 30, textAlign: 'center', color: '#FF0000' }}>
                                    Preencha os campos corretamente
                                </div>
                                :
                                <div />
                        }
                        <Button type="submit" title="ENTRAR" action={() => logInButtonTapped()} isLoading={isLoading} isEnabled={buttonIsEnabled} />
                    </Form>
                </div>
            </div>
            <Modal show={errorModalVisible} onHide={handleClose} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Autenticação falhou</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Confira o seu usuário/senha e por favor tente novamente
                </Modal.Body>
                <Modal.Footer>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button type="submit" title="OK, ENTENDI" action={() => { handleClose() }} isEnabled={true} />
                    </div>
                </Modal.Footer>
            </Modal>
            {
                isLogged ? <Navigate to="/addfiles" replace={true} /> : <div />
            }
            <Footer />
        </div>
    );
}

export default LogIn;