import '../App.css';
import Stack from 'react-bootstrap/Stack';
import gs3logo from '../resources/gs3_logo.png';
import corujasoftLogo from '../resources/corujasoft_logo.png';

function Footer() {
  return (
    <div className='Footer'>
      <Stack direction="horizontal" gap={1}>
        <div>
          <div style={{ color: "#FFF", fontSize: 14 }}>Desenvolvido por Corujasoft - Todos os direitos reservados.</div>
        </div>
        <div className="ms-auto" style={{ paddingRight: 100 }}>
          <a href="https://www.gs3comunicacao.com.br/" target='_blank'><img src={gs3logo} alt="gs3 logo" style={{ height: 64, paddingRight: 16 }} /></a>
          <a href="https://corujasoft.com.br/" target='_blank'><img src={corujasoftLogo} alt="logo" style={{ height: 32 }} /></a>
        </div>
      </Stack>
    </div>
  );
}

export default Footer;