import React, { useEffect, useState } from 'react';
import '../App.css';
import Table from 'react-bootstrap/Table';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Button from '../components/Button';
import Form from 'react-bootstrap/Form';
import Stack from 'react-bootstrap/Stack';
import Network from '../core/Network';
import RequestMapping from '../core/RequestMapping';

function SearchBatch() {

  const [products, setProducts] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [searchedBatch, setSearchedBatch] = useState('');
  const [buttonIsEnabled, setButtonIsEnabled] = useState(false);

  const showPdf = (record) => {
    window.open(record.produtos_data[0].arquivo[0].caminho_arquivo, '_blank');
  }

  useEffect(() => {
    setButtonIsEnabled(searchedBatch != '');
  }, [searchedBatch]);

  const searchBatchDidTap = () => {
    setIsLoading(true);
    var path = searchedBatch == '' ? RequestMapping.SEARCH_BATCH : RequestMapping.SEARCH_BATCH_WITH_FILTER + searchedBatch
    Network.getWithoutToken(path).then((response) => {
      setSearchedBatch('');
      setIsLoading(false);
      var products = [];
      var results = response.data.results;
      for (var i = 0; i < results.length; i++) {
        var productsData = results[i].produtos_data;
        if (productsData && productsData.length) {
          for (var j = 0; j < productsData.length; j++) {
            var files = productsData[j].arquivo;
            for (var k = 0; k < files.length; k++) {
              files[k].codigo_lote = results[i].codigo_lote;
              files[k].descricao = productsData[j].descricao
              files[k].codigo_produto = productsData[j].codigo_produto
              files[k].arquivo = productsData[j].arquivo[0].caminho_arquivo
              products.push(files[k]);
            }
          }
        }
      }
      setProducts(products);
    }).catch((error) => {
      setIsLoading(false);
      setProducts(null);
    });
  }

  function keyboardPressKey(event) {
    if ((event.key === "Enter" || event.keyCode === 13) && buttonIsEnabled) {
      searchBatchDidTap();
    }
  }

  return (
    <div className="PageContainer">
      <Header title="Certificado de Análise" />

      <div className="PageContent CenterHV">
        <div className='CardContentWithList'>
          <h6 style={{ fontWeight: 'bold' }}>Digite o número do lote</h6>
          <Stack direction="horizontal" gap={3} style={{ marginTop: 16 }}>
            <Form.Control className="me-auto" placeholder="Exemplo: 0001 22" onChange={(event) => { setSearchedBatch(event.target.value.replace(/\s/g, '')); }} onKeyDown={(event) => keyboardPressKey(event)} />
            <Button title="CONSULTAR" action={() => { searchBatchDidTap(); }} isLoading={isLoading} isEnabled={buttonIsEnabled} />
          </Stack>
          {
            products ?
              products.length > 0 ?
                <Table striped bordered hover style={{ marginTop: 24 }}>
                  <thead>
                    <tr>
                      <th>Produto</th>
                      <th>Descrição</th>
                      <th>Lote</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      products.map((element, index) => {
                        return (
                          <tr key={index}>
                            <td>{element.codigo_produto}</td>
                            <td>{element.descricao}</td>
                            <td>{element.codigo_lote}</td>
                            <td style={{ width: '120px' }}>
                              {/*<Button title="VER LAUDO" action={() => { showPdf(element); }} isEnabled={true} />*/}
                              <a href={element.arquivo} target="_blank" className="RemoveDefault PrimaryButton">VER LAUDO</a>
                            </td>
                          </tr>
                        );
                      })
                    }
                  </tbody>
                </Table>
                :
                <div style={{ marginTop: 64, textAlign: 'center', paddingBottom: 36 }}>
                  Não foi encontrado nenhum registro para esse lote.
                </div>
              :
              <div />
          }
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default SearchBatch;