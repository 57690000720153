import '../App.css';
import Stack from 'react-bootstrap/Stack';
import logo from '../resources/jpfarma_logo.png';
import { useState } from 'react';

function Header(props) {

  const [clock, setClock] = useState('');

  function time() {
    var datetime = new Date();
    var day = datetime.getDate()
    var month = datetime.getMonth() + 1;
    var year = datetime.getFullYear();
    var seconds = datetime.getSeconds();
    var minutes = datetime.getMinutes();
    var hour = datetime.getHours();
    setClock( day + "/" + month + "/" + year + " " + ("0" + hour).substr(-2) + ":" + ("0" + minutes).substr(-2) + ":" + ("0" + seconds).substr(-2));
  }

  setInterval(time, 1000);

  return (
    <div className='Header'>
      <Stack direction="horizontal" gap={1}>
        <div>
          <img src={logo} alt="logo" style={{ height: 64 }} />
        </div>
        <div className="ms-auto" style={{ paddingRight: 100 }}>
          <div style={{ color: "#FFF", fontWeight: 'bold' }}>{props.title}</div>
          <div style={{ color: "#FFF", fontSize: 14 }}>JP Indústria Farmacêutica S.A.</div>
          <div style={{ color: "#FFF", fontSize: 14 }}>Data {clock}</div>
          <span id="span"></span>
        </div>
      </Stack>
    </div>
  );
}

export default Header;