import React, { useState, useEffect } from 'react';
import { Navigate } from "react-router-dom";
import LocalStorageKey from './LocalStorageKey';

function Middleware() {
  const [isLogged, setIsLogged] = useState(true);

  useEffect(() => {
    let accessToken = localStorage.getItem(LocalStorageKey.TOKEN)
    setIsLogged(accessToken != null && accessToken != '');
  }, []);

  return (
    isLogged ? <div /> : <Navigate to="/" replace={true} />
  );
}

export default Middleware;