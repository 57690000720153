import '../App.css';
import Footer from '../components/Footer';
import Header from '../components/Header';

function ErrorPage() {
  return (
    <div className="PageContainer">
      <Header title="Envio de Certificado de Análise" />
      <div className="PageContent CenterHV">
        Conteúdo não encontrado
      </div>
      <Footer />
    </div>
  );
}

export default ErrorPage;